import React from 'react'
import './index.scss'
import {TitleMedium} from '../Title';
import Button from '../Button';

function Input({type = "text", placeholder, label, name, ...props}) {
   return (
      <div className="Input">
         <input type={type} placeholder={placeholder} name={name} {...props}/>
         <span className="label">{label}</span>
      </div>
   )
}

function NewsLetterFormLong() {
   return (
      <form className="NewsLetterFormLong"
            action="https://envelopead.us12.list-manage.com/subscribe/post?u=19fd5f34ff98bc18d991bed35&amp;id=b117582683"
            onSubmit={e => {
               let [FNAME, ...LNAME] = e.target.FNAME.value.split(' ');

               e.target.FNAME.value = FNAME;
               e.target.LNAME.value = LNAME.join(' ');
            }}
            method="post">
         <TitleMedium>
            Join our mailing list
         </TitleMedium>

         <Input label="E-mail Address" type="email" name="EMAIL"/>
         <Input label="Full Name" name="FNAME"/>

         <Input name="LNAME" type="hidden"/>

         <Input label="Address" maxLength="70" name="MMERGE3[addr1]"/>

         <div className="row">
            <Input label="City" maxLength="40" name="MMERGE3[city]"/>
            <Input label="State / Province / Region" maxLength="20" name="MMERGE3[state]"/>
         </div>

         <div className="row">
            <Input label="Postal / Zip Code" maxLength="10" name="MMERGE3[zip]"/>
            <Input label="Country" name="MMERGE3[country]" />
         </div>

         <Button type="submit"
                 className="Subscribe">
            Subscribe
         </Button>
      </form>
   )
}


export default NewsLetterFormLong;