import React from "react"
import PropTypes from "prop-types"
import "./index.scss"
import Arrow from "../Arrow"
import Desc from "../Desc"
import {Swipeable} from 'react-swipeable'

const CSS_CLASS_PREFIX = "slider";
const HUNDRED_PERCENT = 100;

const LeftArrow = (
	{goToPrevSlide}, // eslint-disable-line
) => (
	<div className={`${CSS_CLASS_PREFIX}-backArrow ${CSS_CLASS_PREFIX}-arrow`} onClick={goToPrevSlide}>
		<Arrow.Circle direction={"left"}/>
	</div>
);

const RightArrow = (
	{goToNextSlide}, // eslint-disable-line
) => (
	<div className={`${CSS_CLASS_PREFIX}-nextArrow ${CSS_CLASS_PREFIX}-arrow`} onClick={goToNextSlide}>
		<Arrow.Circle direction={"right"}/>
	</div>
);

const Point = ({active}) => (<div className={`Point ${active ? 'active' : ""}`}/>);

class PhotosSlider extends React.Component {
	constructor(props) {
		super(props);
		let {speed, slides} = props;

		this.sliderRef = React.createRef();

		this.state = {
			speed: speed,
			currentIndex: 0,
			slides: slides,
		};

		this.goToNextSlide = this.goToNextSlide.bind(this);
		this.goToPrevSlide = this.goToPrevSlide.bind(this);

		this.timerEndAnimation = null;
	}

	componentDidMount() {
		this.showStartSlide()
	}

	isDisableBtnPrevSlide() {
		const {currentIndex} = this.state;
		return currentIndex === 0
	}

	isDisableBtnNextSlide() {
		const {currentIndex} = this.state;
		const {slides, countVisible} = this.props;
		return currentIndex === slides.length - countVisible
	}

	beforeSlide (e) {

		if (e && e.preventDefault) {
			e.preventDefault()
		}

		clearTimeout(this.timerEndAnimation);
	}

	goToPrevSlide(e) {
		const {stepScroll, getMinIndex, getMaxIndex, loop, speed} = this.props;
		const {currentIndex, slides} = this.state;
		const countToScroll = Math.min(stepScroll, currentIndex);
		const maxIndex = getMaxIndex(slides);
		const minIndex = getMinIndex(slides);

		this.beforeSlide(e);

		if (currentIndex === minIndex && loop) {
			this.setState({
				currentIndex: currentIndex-1,
				speed,
			});

			this.timerEndAnimation = setTimeout(() => {
				this.setState({
					currentIndex:maxIndex,
					speed: 0,
				});
			}, speed * 1000);

		} else if (currentIndex < getMinIndex(slides) && loop) {
			this.setState(() => {
				setTimeout(() => this.goToPrevSlide());

				return {
					currentIndex: getMaxIndex(slides),
					speed: 0,
				}
			})
		} else if (this.isDisableBtnPrevSlide()) {
			this.setState({
				speed,
			})
		} else {
			this.setState(prevState => ({
				currentIndex: prevState.currentIndex - countToScroll,
				speed,
			}))
		}
	}

	goToNextSlide(e) {
		const {stepScroll, loop, getMaxIndex, getMinIndex, speed} = this.props;
		const {currentIndex, slides} = this.state;
		const maxIndex = getMaxIndex(slides);
		const minIndex = getMinIndex(slides);

		const countToScroll = Math.min(
			stepScroll,
			slides.length - currentIndex - stepScroll,
		);

		this.beforeSlide(e);
		// Exiting the method early if we are at the end of the images array.
		// We also want to reset currentIndex and translateValue, so we return
		// to the first image in the array.
		if (currentIndex === maxIndex && loop) {
			this.setState({
				currentIndex: currentIndex+1,
				speed,
			});

			this.timerEndAnimation = setTimeout(() => {
				this.setState({
					currentIndex:minIndex,
					speed: 0,
				});
			}, speed * 1000);

		} else 	if (currentIndex > maxIndex && loop) {
			this.setState(() => {
				setTimeout(() => this.goToNextSlide());

				return {
					currentIndex: minIndex,
					speed: 0,
				}
			})
		} else if (this.isDisableBtnNextSlide()) {
			this.setState({
				currentIndex: getMinIndex(slides),
				speed,
			})
		} else {
			// This will not run if we met the if condition above
			this.setState(prevState => ({
				currentIndex: prevState.currentIndex + countToScroll,
				speed
			}))
		}
	}

	showStartSlide() {
		const {startIndex} = this.props;

		this.setState({
			currentIndex: startIndex
		});
	}

	getTitle(index) {
		let {getTitle, title} = this.props;

		index= this.getValidCurrentIndex(index);

		return getTitle(index) || title;
	}

	getValidCurrentIndex(index) {
		const {getMaxIndex, getMinIndex} = this.props;
		const {slides} = this.state;

		const maxIndex = getMaxIndex(slides);
		const minIndex = getMinIndex(slides);

		if (index > maxIndex) {
			index = minIndex;
		} else if(index < minIndex) {
			index = maxIndex
		}

		return index;
	}

	render() {
		const {className, sceneClass, countVisible, alwaysShowButtons, loop, disableButtons} = this.props;
		const {slides, speed, currentIndex} = this.state;
		const isDisableLeftButton = this.isDisableBtnPrevSlide();
		const isDisableRightButton = this.isDisableBtnNextSlide();
		let title = this.getTitle(currentIndex);
		const stylesSlider = {
			willChange: "transform",
			transform: `translateX(-${HUNDRED_PERCENT * (currentIndex + 1)}%)`,
			transition: `transform ease-out ${speed}s`,
		};
		const stylesSlide = {
			width: `${HUNDRED_PERCENT / countVisible}%`,
		};

		const swipeHandlers = {
			onSwipedRight: this.goToPrevSlide,
			onSwipedLeft: this.goToNextSlide
		};

		return (
			<>
				<Swipeable {...swipeHandlers} trackMouse={true}
						   className={`${CSS_CLASS_PREFIX}-s-slider-scene ${sceneClass}`}>

					<div className={`${CSS_CLASS_PREFIX}-s-slider ${className}`}
						 ref={this.sliderRef}
					>
						<div
							className={`${CSS_CLASS_PREFIX}-s-slider-wrapper`}
							style={stylesSlider}
						>
							{
								slides.slice(-1).map((Slide) => (
									<div
										className={`${CSS_CLASS_PREFIX}-s-slide slide--1 ${
											"slide-hidden"
											}`}
										key={'-1'}
										style={stylesSlide}
									>
										<Slide/>
									</div>
								))
							}
							{slides.map((Slide, key) => (
								<div
									className={`${CSS_CLASS_PREFIX}-s-slide slide-${key} ${
										currentIndex === key ? "slide-active" : "slide-hidden"
										}`}
									key={key.toString()}
									style={stylesSlide}
								>
									<Slide/>
								</div>
							))}

							{
								slides.slice(0,1).map((Slide) => (
									<div
										className={`${CSS_CLASS_PREFIX}-s-slide slide-last ${
											"slide-hidden"
											}`}
										key={'last'}
										style={stylesSlide}
									>
										<Slide/>
									</div>
								))
							}
						</div>


					</div>
					{(loop || alwaysShowButtons || !isDisableLeftButton) && (
						!disableButtons && <LeftArrow goToPrevSlide={this.goToPrevSlide}/>
					)}

					{(loop || alwaysShowButtons || !isDisableRightButton) && (
						!disableButtons && <RightArrow goToNextSlide={this.goToNextSlide}/>
					)}

				</Swipeable>

				<div className={`points current-${currentIndex}`}>
					{
						slides.map((_, index) => <Point key={index} active={index === this.getValidCurrentIndex(currentIndex)}/>)
					}
				</div>

				{title && <Desc.Normal className={"SliderDesc"} children={title}/>}
			</>
		)
	}
}

PhotosSlider.propTypes = {
	countVisible: PropTypes.number,
	className: PropTypes.string,
	alwaysShowButtons: PropTypes.bool,
	disableButtons: PropTypes.bool,
	stepScroll: PropTypes.number,
	startIndex: PropTypes.number,
	getMinIndex: PropTypes.func,
	getMaxIndex: PropTypes.func,
	loop: PropTypes.bool,
	speed: PropTypes.number,
	slides: PropTypes.arrayOf(PropTypes.any).isRequired,
	title: PropTypes.string,
	getTitle: PropTypes.func
};

PhotosSlider.defaultProps = {
	countVisible: 1,
	className: "",
	sceneClass: '',
	loop: true,
	alwaysShowButtons: false,
	disableButtons: false,
	stepScroll: 1,
	startIndex: 0,
	speed: 0.3,
	getMinIndex: () => 0,
	getMaxIndex: slides => slides.length - 1,
	getTitle: () => ''
};

export default PhotosSlider
