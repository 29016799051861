import React from "react"
import PropTypes from "prop-types"

export default class Hover extends React.Component {

  constructor(props ){
    super(props);
    this.state = {isHover: false};

    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  onMouseOver() {
    this.setState({isHover: true});
    this.onHover(true);
  }

  onMouseOut() {
    this.setState({isHover: false});
    this.onHover(false);
  }

  onHover (isHover, key) {
    this.props.onHover(isHover, key)
  }

  render() {
    let { children, onHover, ...props } = this.props
    let { isHover } = this.state;

    return (
      <div className="hover-wrap-scope"
         onMouseOver={this.onMouseOver}
         onMouseOut={this.onMouseOut}
         {...props}>
        {children.call ? children(isHover) : children}
      </div>
    )
  }
}

Hover.propTypes = {
  children: PropTypes.any.isRequired,
  onHover: PropTypes.func,
};

Hover.defaultProps = {
  onHover: () => {},
};