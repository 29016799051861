import React from "react"
import './index.scss'
import {Link} from 'gatsby'

export default function Button({className = '', children, ...props}) {

  return (
    <button className={`Button ${className}`}
            type='button'
            {...props}
    >
      {children}
    </button>
  );
}

Button.Back = (props) => {
  return <Button className='ButtonBack' {...props}>Back</Button>
};

Button.HistoryBack = (props) => {
  return (<Button className='ButtonBack'
                 {...props}
                 onClick={() => window['history'].back()}>
     Back
  </Button>)
};

Button.Next = ({to, ...props}) => {
   return <Link to={to}><Button className='ButtonNext' {...props}>Next</Button></Link>
};

Button.Top = (props) => {
  return (
    <Button className='ButtonTop'
            {...props}
            onClick={() => window.scrollTo(0,0)}>
      Top
    </Button>)
};