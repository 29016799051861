import React from 'react';
import './index.scss';
import Desc from '../Desc';
import Image from '../Image';
import {getImageMediumNameId} from '../../helpers/utils';
import Img from 'gatsby-image';

function Photo({desc, photo, fluidMap = {}}) {
    let imageId = `${getImageMediumNameId(photo)}`;

    return <div className="Photo">
        {fluidMap[ imageId ] ? <Img fluid={fluidMap[ imageId ]} className={'Image'}/> : <Image src={photo}/>}
        <Desc.Normal children={desc}/>
    </div>;
}

export default Photo;
