/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import "./index.scss"

const Layout = ({ children, Footer, className = '', siteMap, postMeta}) => (
  <div className={`page-layout-scope ${className}`}>
    <Header postMeta={postMeta} linksMap={siteMap}/>
    <main>{children}</main>
     {Footer}
  </div>
);

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout
