import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import AudioSource from "../AudioSource"


let player;

export default function AudioCollection({ className, collection }) {
  let [activeIndex, setActiveIndex] = useState(null);

  function playAudio(source, index) {
    setActiveIndex(index);
    player.src = source.src;
    player.play().catch(err => {
      console.warn(err);
    });
  }

  useEffect(() => {
    player = new Audio();
  }, [])

  return (
    <div className={`AudioCollection ${className}`}>
      {
        collection.map((source, index) => (
          <AudioSource {...source}
                       key={index.toString()}
                       onClick={() => playAudio(source, index)}
                       active={index === activeIndex}/>
        ) )
      }
    </div>
  )
}

AudioCollection.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

