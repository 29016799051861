import React from "react"
import "./index.scss"
import SplitterSymbol from "../SplitterSymbol"
import {WebsiteLink} from "../WebsiteLink"


function MetaItem({name, value}) {
   return (
      <>
         <p className="MetaItem">{name && `${name}: `}{value}</p>
         <SplitterSymbol/>
      </>
   )
}

export function PostMeta({meta}) {
   let {
      location,
      typology,
      year,
      size,
      constructionCost,
      website,
      dimensions,
      area
   } = meta;

   return (
      <div className='PostMeta row'>
         {area && <MetaItem name="Area" value={area}/>}
         {location && <MetaItem name="Location" value={location}/>}
         {dimensions && <MetaItem name="Dimensions" value={dimensions}/>}
         {typology && <MetaItem name='typology' value={typology}/>}
         {year && <MetaItem name='year' value={year}/>}
         {size && <MetaItem name='size' value={size}/>}
         {constructionCost && <MetaItem name='construction cost' value={constructionCost}/>}
         {website && <><WebsiteLink link={website}/><SplitterSymbol/></>}
      </div>
   )
}