import React from "react"
import Image from "../Image"
import "./index.scss"
import {DescSmall} from "../Desc"
import {WebsiteLink} from "../WebsiteLink"
import Img from 'gatsby-image';


let PreviewLinkComponent = ({data}) => {

   if (!data) {
      return 'Error here'
   }

    return (
      <div className="PreviewLinkComponent">
          {data.fluid ? <Img fluid={data.fluid} className={'Image'}/>: <Image src={data.image}/>}
         <DescSmall>
            {data.text}
            <WebsiteLink name={"Link"} link={data.url}/>
         </DescSmall>
      </div>
   )
}

export default PreviewLinkComponent
