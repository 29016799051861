import React from "react"
import PropTypes from "prop-types"
import './index.scss'
import Arrow from "../Arrow"
import {isReactElement} from "../../helpers/utils";
import AnimateHeight from "react-animate-height";

export default function DropDown ({isOpen, showArrow, onToggle, classNamePrefix, className, header, children, background}) {

   return (
    <div className={`${classNamePrefix} ${className} theme ${background} custom-drop-down ${isOpen ? 'show' : ''}`}>

      <div className={`${classNamePrefix}-header custom-drop-down-header`} onClick={() => onToggle(!isOpen)}>
        {header}

        {showArrow && <Arrow direction={isOpen ? 'up' : 'down'}/>}
      </div>

      <AnimateHeight
          className={`${classNamePrefix}-content custom-drop-down-content`}
          easing={'ease-out'}
          duration={200}
          height={isOpen ? "auto" : 0} >

         {
            React
               .Children
               .toArray(children)
               .map((child, key) => (isReactElement(child) ? child : <div className="simple-text" key={key}>{child}</div>))
         }
      </AnimateHeight>
    </div>
  )
}


DropDown.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  showArrow: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.any.isRequired,
};

DropDown.defaultProps = {
  isOpen: false,
  showArrow: true,
  classNamePrefix: 'drop-down',
  className: '',
  children: "Not found content",
  onToggle: ()=>{}
};


