import React from 'react';
import './index.scss';
import {normalizeRelativeLink} from '../../helpers/utils';
import {parseLinkParams} from '../../helpers/injectReactComponents';


function Image({className, alt, src, ...props}) {
    let [ descAlt, ...params ] = (alt || '').toString().split('::');
    let mapParams = parseLinkParams(params) || {};
    let imageProps = {...props};

    if (mapParams.width) {
        imageProps.style = {
            width: `${mapParams.width}vw`,
        };
    }

    return (
        <img className={`Image ${className} lazy`}
             alt={descAlt}
             {...imageProps}
             data-src={normalizeRelativeLink(src)}
             src={null}
        />
    );
}

Image.displayName = 'Image';


Image.Large = ({className = '', alt, ...props}) => (
    <Image {...props} alt={alt} className={`${className} ImageLarge`}/>);
Image.Large.displayName = 'Image.Large';

export default Image;
