import React from "react"
import Markdown from "markdown-to-jsx"
import {
    getImageMediumNameId,
    isArray,
    isDOMElementOfType,
    isElementOfType,
    isObject,
    isString,
    normalizeRelativeLink,
} from './utils';

import Desc from "../components/Desc"
import MapLinksInText from "../components/MapLinksInText"
import PreviewLinkComponent from "../components/PreviewLinkComponent"
import Paragraph from "../components/Paragraph"
import DropDown from "../components/DroDown"
import DropDownsContainer from "../components/DropDownsContainer"
import Image from "../components/Image"
import Title from "../components/Title"
import Photo from "../components/Photo"
import PhotosSlider from "../components/Slider"
import Interviews from "../components/Interviews"
import HorizontalScroll from "../components/HorizontalScroll"
import References from "../components/References";
import Video from "../components/Video";
import NewsLetterFormLong from "../components/NewsLetterLongForm";
import Img from 'gatsby-image'
import _ from 'lodash'

const SPLIT_SYMBOL = '::';
const SPLIT_KEY_VALUE_SYMBOL = '=';

export function parseLinkParams(params) {
    let map = params.length ? {} : null;

    params.forEach(param => {
        let [key, val] = param.split(SPLIT_KEY_VALUE_SYMBOL);
        map[key] = val;
    });

    return map;
}

function parseLink(child) {
   let [text, ...params] = child.props.children.toString().split(SPLIT_SYMBOL);
   return [text, parseLinkParams(params)]
}

/* parse text like -

* :::Tag attr="First attribute"
* Hello world
* end:::Tag
*
* to
*
* <Tag attr="First attribute">
* Hello world
* </Tag>
* */

function replaceShortCodesToTags(text) {

   if (!text) {
      return ''
   }

   return text.toString()
   .replace(/\[\s\]/g, '[]')
   .replace(/(end)?:::\w{2,}.*/g,  shortCode => {
      let partsCode = shortCode.split(':::');
      let [firstPart, tagName] = partsCode;
      let isClosedTag = firstPart === 'end';

      if (isClosedTag) {
         return `</${tagName}>`;
      }

      return `<${tagName}>`;
   })
}

export default function injectComponents(html, components = {}, data = {}) {
   let {references, fluidMap = {}} = data;

    const options = {
      overrides: {
         img: props => {
            let imageId = `${getImageMediumNameId(props.src)}`;

            if (fluidMap[imageId]) {
                return <Img fluid={fluidMap[imageId]} className={'Image ImageLarge'}/>
            }

             // old fallback
             return <Image.Large {...props}/>
         },
         HorizontalScroll,
         DropDown,
         NewsLetterForm: NewsLetterFormLong,
         DropDownCollection: DropDownsContainer,
         Slide: ({children}) => {
            Img.displayName = Image.Large.displayName;
            let collection = React.Children.toArray(children);
            let [title, desc] = collection.filter(child => isString(child));
            let [img] = collection.filter(child => child && child.props && child.props.src);

             return (
               <div className={'ScrollSlide'}>
                  <Desc.Normal>{title}</Desc.Normal>
                  {img}
                  <Desc.Normal>{desc}</Desc.Normal>
               </div>
            )
         },
         Video: ({children}) => {
           let [href] = React.Children.toArray(children)
              .filter(child => isDOMElementOfType(child, 'a'))
              .map(child => child.props.href);

           return <Video src={normalizeRelativeLink(href)}/>
         },
         Photo: ({children}) => {
            let [img] = React.Children.toArray(children);

            return <Photo desc={img.props.alt} photo={img.props.src} fluidMap={fluidMap}/>
         },
         Title: ({children}) => {
            return (<Title.Large>{children}</Title.Large>)
         },
         References: () => {
            let links = references.map(({title, postRef, image, link}) => {
                let fluid = _.get(image, 'small.childImageSharp.fluid');

               if (image) {
                  image = image.url;
               } else {
                  image = postRef && postRef.hero ? postRef.hero.url : null
               }

               return {
                  text: title,
                  fluid,
                  url: link || `/work/${postRef ? postRef.slug : 404}`,
                  image
               }
            });

            return <References links={links}/>
         },
         Credits: () => {
            return null
         },
         Row: ({children}) => {
           return (<div className="Row row flex-center">{children}</div>)
         },
         Paragraph: ({children}) => {
            let mapLinks = {};
            let text = React.Children.toArray(children)
               .map(section => {
                  let children;
                  let afterText = '';

                  // if not node
                  if (!isObject(section)) {
                     return section || '';
                  }

                  // if link
                  if (isDOMElementOfType(section, 'a')) {
                     let [text, params] = parseLink(section);

                     if (params) {

                         mapLinks[text] = {
                             ...params,
                             fluid: fluidMap[getImageMediumNameId(params.image)],
                             url: section.props.href
                         };
                     }

                     return text;
                  }

                  if (isDOMElementOfType(section, 'p')) {
                     afterText = `\n\n`;
                  }

                  children = section.props.children;

                   if (isArray(children)) {
                     children = '';
                     React.Children.toArray(section.props.children).forEach(child => {
                        if (isString(child)) {
                           children += child;
                           return false;
                        }

                        if (isDOMElementOfType(child, 'a')) {
                           let [text, params] = parseLink(child);

                           children += text;

                           if (params) {
                              mapLinks[text] = {
                                  ...params,
                                  fluid: fluidMap[getImageMediumNameId(params.image)],
                                  url: child.props.href};
                           }

                           return false
                        }

                        children += child.toString();
                     })
                  }

                  return children + afterText
               })
               .join("");

            return (
               <Paragraph>
                  <Desc.Normal>
                     <MapLinksInText
                        mapLinks={mapLinks}
                        previewComponent={PreviewLinkComponent}
                        children={text}/>
                  </Desc.Normal>
               </Paragraph>
            )
         },
         Sounds: ({children, ...props}) => {
            let collection = React.Children.toArray(children);
            let [img] = collection.filter(child => isElementOfType(child, Image.Large))
               .map(child => child.props.src);

            let audioCollection = collection.filter(child => isDOMElementOfType(child, 'a'))
               .map(({props}) => ({
                  src: props.href,
                  name: props.children.toString(),
               }));

            return <Interviews collection={audioCollection} photo={img} {...props} />
         },
         Slider: ({children, title, background}) => {

            let labels = [];
            let slides = React.Children.toArray(children)
               .filter(isObject)
               .map(child => {
                  labels.push(child.props.alt);

                   let imageId = `${getImageMediumNameId(child.props.src)}`;

                   if (fluidMap[imageId]) {
                       return () => <Img alt={child.props.alt} fluid={fluidMap[imageId]} className={'Image ImageLarge'}/>
                   }

                  return () => (<Image.Large src={child.props.src} alt={child.props.alt}/>)
               });

            return (
               <div className={`theme ${background}`}>
                  <PhotosSlider
                     sceneClass={`origin-slider`}
                     title={title}
                     getTitle={key => {
                        return [...labels][key]
                     }}
                     slides={[...slides]}/>
               </div>)
         },
         ...components
      },
   };

   return (<Markdown children={replaceShortCodesToTags(html)} options={options} className="Markdown"/>)
}


