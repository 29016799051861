import React from "react"
import PropTypes from "prop-types"
import "./index.scss"

function IconSound({ active }) {
  return (<div className={`IconSound theme-invert ${active ? "active" : ""}`}/>)
}

IconSound.defaultProps = {
  active: false,
};


export default function AudioSource({ className, name, active, ...props }) {
  return (
    <div className={`AudioSource ${className}`}
         {...props}
    >
      <IconSound active={active}/>
      <p className={`name ${active && "active"}`}>{name}</p>
    </div>
  )
}

AudioSource.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
};

AudioSource.defaultProps = {
  active: false,
  className: "",
};