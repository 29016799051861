import React from 'react';
import './index.scss'


// 48px
export function TitleLarge ({children, className}) {
  return (
    <h3 className={`title Title TitleLarge ${className}`}>{children}</h3>
  )
}


// 32px
export function TitleMedium ({children}) {
  return (
    <h4 className='title Title TitleMedium'>{children}</h4>
  )
}

// 21px
export function TitleSmall ({children}) {
  return (
    <h4 className='title Title TitleSmall'>{children}</h4>
  )
}

// 14/21
export function TitleExtraSmall ({children}) {
  return (
    <h5 className='title Title TitleExtraSmall'>{children}</h5>
  )
}

export default class Title extends React.Component {
  static Small = TitleSmall;
  static Medium = TitleMedium;
  static Large = TitleLarge;
  static ExtraSmall = TitleExtraSmall;

  render() {
    return null
  }
}