import React from 'react';
import './index.scss';


function Footer({className}) {
   return (<div className={`Footer ${className}`}>
      <p>
         <a href="mailto:info@envelopead.com">info@envelopead.com</a>
         <span className="splitter-symbol"> / </span>
         <span> 2212 Sixth Street Berkeley</span>
      </p>

      <p>
         <a href="https://www.facebook.com/envelopead" target="_blank">Facebook</a>
         <span children={' / '} />
         <a href="https://www.instagram.com/envelopead"  target="_blank">Instagram</a>
      </p>
   </div>);
}



export default Footer;
