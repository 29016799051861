import React from "react"
import {Link} from 'gatsby'
import lottie from 'lottie-web'
import './index.scss'
import splashScreenData from '../../images/envelope-long';
import {getWindow, isRootLocation} from '../../helpers/utils';

let player = null;
const IS_STORE_PREV_PAGE = getWindow().sessionStorage.getItem('PREV_LOCATION');

const LogoBlob = ({containerSelector}) => {

  React.useEffect(() => {
      let container = document.querySelector(containerSelector);
      const IS_MOBILE = document.body.offsetWidth < 768;

      player = lottie.loadAnimation({
          container, // the dom element that will contain the animation
          renderer: 'svg',
          loop: !IS_MOBILE,
          autoplay: isRootLocation() && IS_MOBILE && IS_STORE_PREV_PAGE,
          animationData: splashScreenData,
      });
  }, []);

  return (
    <Link to={'/'} >
        <div id={'logo-blob'}
             onMouseEnter={() => player && player.play()}
             onMouseLeave={() => player && player.pause()}
             onClick={() => player && player.play()}
             className={'logo'}
        />
    </Link>
)};

LogoBlob.defaultProps = {
    containerSelector: '#logo-blob'
};

export default LogoBlob


