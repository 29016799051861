import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import "./index.scss"
import Hover from "../Hover"
import { WebsiteLink } from "../WebsiteLink"
import { replaceSpacesTo } from "../../helpers/textFormatter"

const REPLACE_MARKET = "<jsx>";

export default function MapLinksInText({ children, mapLinks, previewComponent }) {
  let [hoverText, setHoverText] = useState('');
  let wrapClassName = hoverText ? `${replaceSpacesTo(hoverText).toLowerCase()} hover` : "";
  let Preview = previewComponent;
  let listHoverComponents = [];
  let text = children.toString();

   Object.keys(mapLinks).forEach(word => {
    text = text.replace(word, (match, index, input_string) => {
      listHoverComponents.push(
        <Hover className={`Inline ${hoverText === word ? "on-hover-word" : "off-hover-word"}`}
               onHover={() => setHoverText(word)}>

          <WebsiteLink link={mapLinks[word].url} name={word} hasArrow={false}/>
        </Hover>,
      );

      return REPLACE_MARKET
    })
  });

   return (
    <>
      <div className={`text-with-hover-words ${wrapClassName}`}>
        {text.split(REPLACE_MARKET).map((phrase, index) => (
          <Fragment key={index.toString()}>
            {phrase}
            {listHoverComponents[index]}
          </Fragment>
        ))}
        {mapLinks[hoverText] && <Preview data={mapLinks[hoverText]} word={hoverText}/>}
      </div>
    </>)
}

MapLinksInText.propTypes = {
  children: PropTypes.string.isRequired,
  mapLinks: PropTypes.objectOf(
    PropTypes.object,
  ).isRequired,
}