import React from 'react'
import './index.scss'

export default function Video({src, className}) {
   let classNames = [
      className || '',
      'Video'
   ].join(' ');

   return (<video className={classNames} src={src} playsInline controls />)
}
