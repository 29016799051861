import {Link} from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect, Fragment} from "react"
import "./index.scss"
import LogoBlob from "../LogoBlob"
import SplitterSymbol from "../SplitterSymbol"
import {PostMeta} from "../PostMeta"
import {getWindow} from "../../helpers/utils";


let confMenu = {
	categoriesOptions: {
		useSplitter: false,
		classNameMenu: "header-menu row",
		classNameLink: "general-link",
	},
	categories: {
		"/work": {
			url: "/work",
			name: "Work",
			categoriesOptions: {
				classNameMenu: "header-menu-subcategories row",
				classNameLink: "general-subcategory-link",
			},
			categories: {
				"/idea": {
					name: "idea",
					url: "/idea",
					categoriesOptions: {
						useSplitter: true,
						classNameMenu: "header-menu-sub-subcategories row",
						classNameLink: "general-sub-subcategory-link",
						hasCounter: true,
						all: {
							name: 'All',
							url: '/all'
						},
					},
					categories: {},
				},
				"/type": {
					name: "type",
					url: "/type",
					categoriesOptions: {
						useSplitter: true,
						classNameMenu: "header-menu-sub-subcategories row",
						classNameLink: "general-sub-subcategory-link",
						hasCounter: true,
						all: {
							name: 'All',
							url: '/all'
						},
					},
					categories: {},
				},
			},
		},
		"/news": {
			url: "/news",
			name: "News",
			categoriesOptions: {
				useSplitter: true,
				classNameMenu: "header-menu-sub-subcategories row",
				classNameLink: "general-sub-subcategory-link",
			},
			categories: {
				"/all": {
					name: "All News",
					url: "/all",
				},
				"/events": {
					name: "Events",
					url: "/events",
				},
				"/announcements": {
					name: "Announcements",
					url: "/announcements",
				},
				"/publications": {
					name: "Publications",
					url: "/publications",
				},
			},
		},
		"/studio": {
			url: "/studio",
			name: "Studio",
			categoriesOptions: {
				useSplitter: true,
				classNameMenu: "header-menu-sub-subcategories row",
				classNameLink: "general-sub-subcategory-link",
			},
			categories: {
				"/philosophy": {
					name: "Philosophy",
					url: "/philosophy",
				},
				"/teams-bios": {
					name: "Team Bios",
					url: "/team-bios",
				},
				"/collaborators": {
					name: "Collaborators",
					url: "/collaborators",
				},
				"/contact": {
					name: "Contact",
					url: "/contact",
				},
			},
		},
	},
};

function isActiveLink(link) {
	let location = getWindow().location;
	let parts = location.pathname.split('/').filter(part => part);

	link = link.replace('/', '');

	return {
		isActive: parts.includes(link),
		inEndUrl: parts.pop() === link
	}
}

function menuGenerator(config) {
	let content = [];
	let baseUrl = "";
	let locationParts = getWindow().location.pathname.split("/").filter(val => val);
	let index = 0;

	locationParts.unshift("");

	do {
		let menu = <MenuLinks
			links={Object.values(config.categories)}
			key={index.toString()}
			baseUrl={baseUrl}
			options={config.categoriesOptions}
		>{((activeLink, baseURL) => {

			return (<MenuLinks
				links={Object.values(activeLink.categories)}
				baseUrl={baseURL}
				inline={true}
				options={activeLink.categoriesOptions}
			/>)
		})}</MenuLinks>;

		if (!config.categoriesOptions.renderChildInnerMenu) {
			content.push(menu)
		}

		index++;
		baseUrl += `/${locationParts[index]}`;
		config = config.categories[`/${locationParts[index]}`];

		if (!config || !config.categories) {
			return content
		}

	} while (index < locationParts.length);

	return content
}


function MenuLinks({links, options = {}, baseUrl, children, inline}) {
	let {classNameMenu, classNameLink, all, useSplitter} = options;
	let isRenderChildInnerMenu = false;

	baseUrl = baseUrl || '';


	if (all) {
		links = [all, ...links];
	}

	let Links = links.map((link, index) => {
		let hasCounter = typeof link.count === "number";
		let isLast = index === links.length - 1;
		let {isActive, inEndUrl} = isActiveLink(link.url);

		isRenderChildInnerMenu = link.categoriesOptions && link.categoriesOptions.renderChildInnerMenu;


		return (
			<Fragment key={link.name}>
				<li className={inline ? 'inline' : ''}>
					<Link
						className={`${classNameLink} ${isActive ? 'active' : ''} ${inEndUrl ? 'end' : ""}`}
						to={`${baseUrl}${link.url}`}
					>
						{link.name}

						{hasCounter && <sup className="index"> {link.count}</sup>}
					</Link>

				</li>
				{/* Render content after active link */}
				{isActive && isRenderChildInnerMenu && children && children(link, `${baseUrl}${link.url}`)
				}
				{/* Splitter between Links */}
				{useSplitter && !isLast && <SplitterSymbol/>}
			</Fragment>)
	});

	return (inline ? Links : <menu className={classNameMenu} children={Links}/>)
}

function lockPageScroll(isLock) {
	document.body.style.height = isLock ? '100vh' : '';
	document.body.style.overflow = isLock ? 'hidden' : ''
}

function isCompareCurrentAndPrevRoute() {
	let _window = getWindow();
	const LENGTH_SYMBOLS_TO_COMPARE = 5;
	let prevLocation = _window.sessionStorage.getItem('PREV_LOCATION') || '';
	let currentLocation = _window.location.pathname;
	let isSamePath = prevLocation.slice(0, LENGTH_SYMBOLS_TO_COMPARE) === currentLocation.slice(0, LENGTH_SYMBOLS_TO_COMPARE);
	_window.sessionStorage.setItem('PREV_LOCATION', currentLocation);

	return isSamePath;
}


const Header = props => {
	let {linksMap, postMeta} = props;
	let [isOpenMenu, toggleMenu] = useState(false);
	let isCompareHref = isCompareCurrentAndPrevRoute();

	useEffect(() => lockPageScroll(isOpenMenu), [isOpenMenu]);

	return (
		<header className={`${isOpenMenu ? 'menu-open' : 'close-menu'} ${isCompareHref ? 'same' : 'diff'}-route`}>
			<p className="mobile-menu" onClick={() => {
				toggleMenu(!isOpenMenu);
			}}>Menu</p>
			{menuGenerator(linksMap)}
			{postMeta && <PostMeta meta={postMeta}/>}
			<LogoBlob/>
		</header>
	)
};

Header.propTypes = {
	linksMap: PropTypes.any,
};

Header.defaultProps = {
	linksMap: confMenu,
};

export default Header
