import React from 'react';
import PreviewLinkComponent from '../PreviewLinkComponent';
import './index.scss';


export default function References({links}) {

    return (
        <div className="References row">
            {
                links.map((link, index) => <PreviewLinkComponent data={link}
                                                                 key={index.toString()}/>)
            }
        </div>
    );
}
