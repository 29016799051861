import { window as $window } from 'browser-monads';


export function isElementOfType(
  element,
  Component,
) {
  return (
    element != null &&
    element.type != null &&
    element.type.displayName != null &&
    element.type.displayName === Component.displayName
  )
}

export function isReactElement(element) {
   return (
      element != null &&
      typeof element.type === 'function'
   )
}


export function isDOMElementOfType(
  element,
  type,
) {
  return (
    element != null &&
    element.type != null &&
    element.type === type
  )
}

export function isObject(elem) {
  return typeof elem === "object"
}

export function isString(elem) {
  return typeof elem === "string"
}

export function isArray(elem) {
  return isObject(elem) && elem.push;
}

export function getServerHost() {
   return process.env.API || 'http://localhost:1337'
}

export function getWindow() {
    if (isBrowser()) {
        return window;
    }
   return $window
}

export function isBrowser () {
    return typeof window !== 'undefined';
}

export function normalizeRelativeLink(link) {
   if (!link) {
      return link;
   }

   if (link.includes('://')) {
      return link;
   }

   return `${getServerHost()}${link}`
}

export function isLocalLink(link) {
  let {hostname} = getWindow().location;

  if (!link) {
    return false
  }

  link = link.toString().trim();

  if (['/', '.'].includes(link[0].toString())) {
    return  true
  }

  return link.includes(hostname.toString());
}

export function isRootLocation() {
    return getWindow().location.pathname === '/'
}


export const IMAGE_SIZES = {
  MEDIUM: 800
};


export const getImageNameId = src => src && src.split('/').pop().replace(/\.(png|jpg|jpeg).*/ig, '');

export const getImageMediumNameId = src =>`${getImageNameId(src)}_${IMAGE_SIZES.MEDIUM}`;
