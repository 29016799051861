import React from "react"
import "./index.scss"
import {Link} from  'gatsby'
import { isLocalLink } from "../../helpers/utils"

export function WebsiteLink({ name, link, hasArrow }) {
   link = link || '';
   let isLocal = isLocalLink(link);

  return (
    <div className='WebsiteLink row'>
       {isLocal ? <Link children={name} to={link}/> : <a href={link} target="_blank">{name}</a>}
       <i className={`link-arrow ${hasArrow ? '' : 'hide'}`} />
    </div>
  )
}

WebsiteLink.defaultProps = {
  name: "WEBSITE",
  hasArrow: true
};

export const replaceLinkComponent = {
    a: props => {
        let {href, children} = props;

        return  (
            <div className="Inline on-hover-word">
                <WebsiteLink name={children.toString()} link={href} />
            </div>
        )
    }
};
