import React from 'react';
import './index.scss'


// 21/30
export function DescNormal ({children, className = ''}) {
  return (
    <div className={`desc Desc DescNormal ${className}`}>{children}</div>
  )
}

// 14/18
export function DescSmall ({children, className}) {
  return (
    <div className={`desc Desc DescSmall ${className}`}>{children}</div>
  )
}

export default class Desc extends React.Component {
  static Normal = DescNormal;
  static Small = DescSmall;

  render() {
    return null
  }
}