import React from "react"
import DropDown from "../DroDown"
import PropTypes from "prop-types"

function parseData(json, _default) {
	if (typeof json !== 'string') {
		return json || _default;
	}

	try {
		json = JSON.parse(json)
	} catch (e) {
		json = _default;
	}

	return json || _default;
}

export default class DropDownsContainer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			activeIndex: props.activeIndex,
			activeIndexes: parseData(props.activeIndexes, [])
		}
	}

	onToggle = (index, isOpen) => {
		let {isSingleOpen, onToggle} = this.props;
		let {activeIndexes} = this.state;
		let newActiveIndex;

		if (isSingleOpen) {
			newActiveIndex = this.state.activeIndex === index ? null : index;
			this.setState({activeIndex: newActiveIndex});
			onToggle(newActiveIndex);
			return false;
		}

		activeIndexes[index] = isOpen;
		this.setState({activeIndexes: [...activeIndexes]});
		onToggle(activeIndexes);
	};

	render() {
		let {children, isSingleOpen, bottomBorder, showBottomBorder} = this.props;
		let {activeIndex, activeIndexes} = this.state;
		let isAnyOpened = isSingleOpen ? typeof activeIndex === 'number' : activeIndexes.includes(true);
		let isShowedBottomBorder = bottomBorder && !isAnyOpened;

		// If border bottom add from cms
		isShowedBottomBorder = isShowedBottomBorder || showBottomBorder;

		return (
			<div className={`DropDownsContainer drop-down-wrap ${ isShowedBottomBorder ? 'show-bottom-border' : ''}`}>
				{React.Children.map(children, (child, index) => (
					<DropDown
						{...child.props}
						key={index.toString()}
						isOpen={isSingleOpen ? index === activeIndex : !!activeIndexes[index]}
						onToggle={isOpened => this.onToggle(index, isOpened)}/>
				))}
			</div>
		)
	}
}

DropDownsContainer.defaultProps = {
	activeIndex: 0,
	activeIndexes: [],
	isSingleOpen: false,
	bottomBorder: false,
	showBottomBorder: false,
	onToggle: () => {
	},
};


DropDownsContainer.propTypes = {
	activeIndex: PropTypes.number,
	activeIndexes: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	]),
	isSingleOpen: PropTypes.bool,
	bottomBorder: PropTypes.bool,
	showBottomBorder: PropTypes.bool,
	onToggle: PropTypes.func,
};

