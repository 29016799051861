import React from 'react';
import './index.scss';


export default class HorizontalScroll extends React.Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {translate: 0, scrollWidth: 0};
    }

    onWheel = e => {
        let {deltaY} = e;
        let {translate, scrollWidth} = this.state;
        let shift = deltaY > 0 ? 1 : -1;
        let speed = 30;

        translate -= (shift * speed);

        if (translate > 0) {
            translate = 0;
        } else if (translate < scrollWidth * -1) {
            translate = scrollWidth * -1;
        } else {
            e.stopPropagation();
            e.preventDefault();
        }

        this.setState({translate});
    };

    componentDidMount() {
        this.ref.current.addEventListener('wheel', this.onWheel, true);
        let {scrollWidth, offsetWidth} = this.ref.current;

        scrollWidth -= offsetWidth;

        this.setState({scrollWidth})
    }

    render() {
        let {translate} = this.state;

        return (
            <div className={'HorizontalScroll'}
                 ref={this.ref}>
                <div style={{transform: `translate3d(${translate}px, 0, 0)`}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


