import React from "react"
import Image from "../Image"
import "./index.scss"
import AudioCollection from "../AudioCollection"
import Title from "../Title"


let Interviews = ({photo, collection, header}) => (
  <div className="Interviews row">
    <Title.Medium children={header}/>
    <AudioCollection collection={collection}/>
    <Image src={photo} />
  </div>
);

Interviews.defaultProps = {
  header: 'Interviews'
}

export default Interviews